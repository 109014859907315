<template>
  <div></div>
</template>
<script>
  import { mapActions } from 'vuex';

  export default {
    name    : 'Login',
    methods : {
      ...mapActions({
        'socialLogin' : 'auth/socialLogin',
      }),
    },
    mounted() {
      const oauthToken = this.$route.query.oauth_token;
      const oauthVerifier = this.$route.query.oauth_verifier;
      const oauthTokenSecret = localStorage.oauthTokenSecret;

      this.$http.post('/auth/twitter/callback', {
        // eslint-disable-next-line camelcase
        oauth_token        : oauthToken,
        // eslint-disable-next-line camelcase
        oauth_verifier     : oauthVerifier,
        // eslint-disable-next-line camelcase
        oauth_token_secret : oauthTokenSecret,
        userType           : (localStorage.getItem('age') < 18) ? 1 : 2,
        inviterId          : localStorage.inviterId || 0,
        birthday           : localStorage.birthdate,
      }).then((res) => {
        // store accessToken and refreshToken
        this.socialLogin({
          accessToken            : res.data.accessToken,
          refreshToken           : res.data.refreshToken,
          expiryAccessTokenUnix  : res.data.expiryAccessTokenUnix,
          expiryAccessTokenDate  : res.data.expiryAccessTokenDate,
          expiryRefreshTokenUnix : res.data.expiryRefreshTokenUnix,
          expiryRefreshTokenDate : res.data.expiryRefreshTokenDate,
        });
        this.$analytics.fbq.event('login-social', {
          'platform'    : 'website',
          'socialMedia' : 'twitter',
        });
      }).catch((e) => {
        this.$store.commit('notification/SET_ERROR', e.response.data.msg);
      });
    },
  };
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/components/authentication/twitter-callback";
</style>
